import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const NewBuilding = () => {
    return (
        <Layout>
            <Seo title="New Building" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>New Building</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        At Delta Corp Ship Management, we focus on supervision of product and chemical carriers, gas
                                        carriers, all types of tankers, bulk carriers and container vessels. Our services cover all the
                                        vessel’s construction phases and assessment of sites, selection of the project manager,
                                        drawings approval, maker’s selection, building phase’s supervision and commissioning, as well
                                        as final assessment and delivery.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="card mb-3 border-0">
                            <div className="row gx-3">
                                <div className="col">
                                    <div className="card-body gray-bg d-flex flex-column">
                                        <h3>Pre-building supervision services</h3>
                                        <p className="card-text">
                                            At Delta, our constant objective is to provide Owners with safe, cost-efficient, and
                                            environmentally-sound services meeting agreed specifications for Quality Management,
                                            focusing on continuously minimizing process losses and downtime and achieving zero failure.
                                        </p>
                                        <div className="mb-3">
                                            <h4>Audit of shipyards and subcontractor facilities</h4>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">
                                                    In depth audits carried out in all pre-selected Subcontractors and detailed reports
                                                    submitted to Owners.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Screening and recommendations for all Hull, Coating and Outfitting subcontractors.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mb-3">
                                            <h4>Design and drawing appraisal</h4>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">Optimization of preliminary ship design.</li>
                                                <li className="list-group-item gray-bg">
                                                    Plans, design drawings and maker’s instruction manuals review to ensure documents
                                                    in line with contractual plans and specifications and as well compliance with
                                                    flag/class regulations.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Focus on vetting requirements and future regulations.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Critical areas for construction and through-life maintenance identified to
                                                    incorporate highest safety standards, reliability, focus on operational design
                                                    improvement and smooth operation.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Structured electronic database for all PCF forms and relevant supporting
                                                    documentation.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Tight budgetary control of extra/credit items.
                                                </li>
                                            </ul>
                                        </div>
                                        <StaticImage
                                            className="mb-3"
                                            transformOptions={{ cropFocus: "attention" }}
                                            alt="Sales team"
                                            src="../../../images/other/container1.jpg"
                                            width={696}
                                        />
                                        <div className="mb-3">
                                            <h4>Technical negotiations and subcontractors review</h4>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">
                                                    Ensuring improvements in the vessel’s technical equipment, systems and fittings.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Ensuring highest construction standards/procedures are applied during building.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Ensuring Owners technical and operations requirements are met in the most
                                                    cost-efficient way.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Ensuring vetting requirements and most recent regulations are applied and upcoming
                                                    foreseen.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="mb-3">
                                            <h4>Assistance in maker selection</h4>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">
                                                    Maker list review to ensure incorporation of all possible upgrades leading to
                                                    trouble free operation and increased added value.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Benefit from a wealth of experience with European and Asian vendors designs,
                                                    quality standards and pricing.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Assisting with maker selection negotiations ensuring cost efficiency throughout the
                                                    project.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mb-3">
                                            <h4>On-site supervision and consultancy</h4>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">
                                                    Selection of site manager in coordination with Owners.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Continuous assessment of contractor’s quality control at works.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    On-site approval of production/detailed drawings.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Participation in technical meetings and co‐ordination with Classification and
                                                    Regulatory bodies.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Continuous assessment of construction progress through weekly/monthly detailed
                                                    reporting to ensure schedules are punctual and delivery dates met.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Review of plans and procedures, examination of all welding related documentation
                                                    with supervision of all welding operations.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Inspections of all equipment and machinery, hull / hull outfitting, surface
                                                    preparation and coating system.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Comprehensive weekly and monthly progress reporting including hull, hull
                                                    outfitting, coating, machinery, machinery outfitting, electrical daily inspections,
                                                    shop test/FAT reports and issued NCR’s.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Attendance in inclining experiment and deadweight measurement, mooring/dock and sea
                                                    trials as well as commissioning of all ships systems.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Attendance to onboard systems commissioning’s and sea trials.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Assessment of variations in specifications and negotiation of extra credit/cost
                                                    items.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Crew familiarization through Extensive crew demonstration list compiled with crew
                                                    and S/Y ensuring that crew is familiar with all equipment on board.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Control of ship documentation, certification and final drawings on board.
                                                </li>
                                                <li className="list-group-item gray-bg">Delivery acceptance of vessel.</li>
                                            </ul>
                                        </div>
                                        <StaticImage
                                            className="mb-3"
                                            transformOptions={{ cropFocus: "attention" }}
                                            alt="Sales team"
                                            src="../../../images/other/tanker5.jpg"
                                            width={696}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="card mb-3 border-0">
                            <div className="row gx-3">
                                <div className="col">
                                    <div className="card-body gray-bg d-flex flex-column">
                                        <h3>Post-building support services</h3>
                                        <p className="card-text">
                                            Technical assistance and Claims handling during vessel’s guarantee period.
                                        </p>
                                        <div className="mb-3">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">
                                                    Coordination of Owner, vessel and shipyard technical communication.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Continuous monitoring and follow-up of each vessel claims during guarantee period.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Technical consultancy on claims handling based on previous sister vessels
                                                    experience when available.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Full technical and commercial advice to Owners at the end of guarantee period.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Direct meetings with makers to ensure vessels minimum downtime.
                                                </li>
                                            </ul>
                                        </div>

                                        <StaticImage
                                            className="mb-3"
                                            transformOptions={{ cropFocus: "attention" }}
                                            alt="Sales team"
                                            src="../../../images/other/bulker3.jpg"
                                            width={696}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NewBuilding
